@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Pujarelah';
  src: url('./fonts/Pujarelah/Pujarelah-Regular.ttf') format('truetype'),
    url('./fonts/Pujarelah/Pujarelah-Regular.otf') format('opentype');
  /* Add other font formats (woff, woff2) for better browser support */
}


@layer base {
  body {
    @apply font-primary text-primary;
  }
}

.font-secondary {
  font-family: 'Pujarelah', sans-serif;
}