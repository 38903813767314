
.image-gallery-icon {
    &:hover {
        color: #F5E6E0;
    }
}


.image-gallery-thumbnail {
    .image-gallery-thumbnail-image {

        &:hover {
            border: 3px solid #F5E6E0;
        }

        &:focus {
            border: 3px solid #F5E6E0;
            outline: none;
        }

        &:active {
            border: 3px solid #F5E6E0;
        }

        &.active {
            border: 3px solid #F5E6E0;

            img {
                opacity: 0.3;
            }
        }
    }

    &:hover {
        border: none;
    }

    &:focus {
        border: none;
    }

    &:active {
        border: none;
    }

    &.active {
        border: none;
    }
}